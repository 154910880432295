import { graphql, StaticQuery } from 'gatsby'
import React, { useState, ComponentDidMount } from 'react'
import Lottie from 'react-lottie';
import Layout from '../components/layout'

import animationData from '../sl-logo-anim-full.json'


const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      name
      address1
      address2
      zipCode
      city
      country
    }
  }
`

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)
  function handleShowNav () {
    setShowNav(true)
    document.getElementById('hamburger').classList.add('active');
  }
  function handleHideNav () {
    setShowNav(false)
    document.getElementById('hamburger').classList.remove('active')
  }

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          )
        }
        if (!data.companyInfo) {
          throw new Error(
            'Missing "Company info". Open the studio at http://localhost:3333 and add "Company info" data'
          )
        }
        return (
          <div id="___layout-container">
            <div
              key={`loader`}
              id="___loader"
              style={{
                alignItems: "center",
                backgroundColor: "#F2F2F2",
                display: "none",
                justifyContent: "center",
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
           >
             <div className='lottie'>
             <Lottie options={defaultOptions}/>
             </div>
        </div>
          <Layout
            {...props}
            showNav={showNav}
            companyInfo={data.companyInfo}
            siteTitle={data.site.title}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
          />
          </div>
        )
      }}
    />
  )
}

export default LayoutContainer
