import React from 'react'
import Header from './header'
import { Link } from 'gatsby'

import '../styles/layout.scss'
import styles from './layout.module.css'

const Layout = ({ children, companyInfo, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
  <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
          © {new Date().getFullYear()} SightLine Ophthalmic Consulting, <span>Built by <a href='https://www.evan-aubrey.com' target='_blank'>Evan Aubrey</a></span>
        </div>
        <div className={styles.footerLinks}>
          <a href="https://www.facebook.com/SightLine-Ophthalmic-Consulting-103718634965998" target='_blank'>Facebook</a>
          {/* <a href="#">Instagram</a> */}
          <a href="https://www.linkedin.com/company/sightline-oc" target='_blank'>LinkedIn</a>
          <Link to={`/privacy-policy`}>Privacy Policy</Link>
        </div>
      </div>
    </footer>
  </>
)

export default Layout
